import classNames from "classnames";
import React, { useEffect, useRef } from "react";

export const VideoPlayer: React.FC<{ videoWidth?:string, stream?:MediaStream, mute?:boolean, id:string ,orientation?:string , onClick?: () => void,degree?:number}> = ({videoWidth,stream,mute,id,orientation='horizontal',onClick,degree}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(()=>{
        if(videoRef.current && stream ) videoRef.current.srcObject = stream;
    },[stream])

    useEffect(()=>{
        //if player stop stream need to change peerstream
        window.addEventListener('pause',(event)=>{
            console.log('stop')
        })
    })
    
    return (<>
        { videoWidth !== 'full' &&<video  className={classNames("h-full", {
            "w-full object-cover 2xl:h-58": orientation === 'horizontal',
            "w-48 object-contain": orientation === 'vertical'
          })} id={id} data-testid="peer-video" ref={videoRef} autoPlay muted={true} onClick={onClick} style={{rotate:`${degree}deg`}}/>}
        { videoWidth === 'full' &&<video  className={classNames("w-full", {
            "object-cover h-full": orientation === 'horizontal',
            "fixed object-contain h-2/3": orientation === 'vertical'
          })} id={id} data-testid="peer-video" ref={videoRef} autoPlay muted={true} onClick={onClick} style={{rotate:`${degree}deg`}}/>}
    </>)
}